<template>
  <div class="sidebar-container">
    <!-- Avatar Section -->
    <div class="avatar text-center">
      <div class="d-flex align-items-center justify-content-center">
        <div class="mt-3 mb-3">
          <div class="row" v-if="user.user_type !== 6">
            <div class="col lh-1 v-step-0">
              <img class="level-badge-img"
                   :src="userLevel.asset_url"
                   title="Level Badge"
                   alt="badge" />
            </div>
          </div>
          <div class="row" v-if="user.user_type !== 6">
            <div class="col-6 vertical-line-left"></div>
            <div class="col-6 vertical-line-right"></div>
          </div>
          <div class="row">
            <div class="col-12 lh-1 v-step-1"
                 :class="{
                   'v-step-8' : user.user_type === 1 ||
                      user.user_type === 3 || user.user_type === 4,
                   'v-step-7' : user.user_type === 2 ||
                      user.activation_used === 0,
                   'v-step-6' : user.user_type === 5
                  }">
              <router-link to="/profile"
                           title="Profile">
                <img class="avatar-img "
                     :src="userPhotoUrl"
                     alt="avatar"
                     v-if="userPhotoUrl" />
                <img class="avatar-img"
                     src="../../assets/images/sample_user_profile.png"
                     alt="default-avatar"
                     v-else />
              </router-link>
            </div>
          </div>
          <div class="row" v-if="user.user_type !== 6">
            <div class="col-6 vertical-line-left"></div>
            <div class="col-6 vertical-line-right"></div>
          </div>
          <div class="row" v-if="user.user_type !== 6">
            <div class="col v-step-2">
              <div class="row">
                <div class="col-12 lh-1">
                  <div class="current-level-container d-flex align-items-center
                        justify-content-center">
                    <span class="current-level"
                          title="Current Level">
                      {{ user.userLevel.level }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 vertical-line-left"></div>
                <div class="col-6 vertical-line-right"></div>
              </div>
              <div class="row">
                <div class="col lh-075">
                  <div class="current-level-progress-container
                    d-flex align-items-center justify-content-center">
                    <span class="current-percentage"
                          title="Level Progress">
                      {{ Math.ceil(expPercentage) + '%' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Navigation Section -->
    <nav class="navbar">
      <ul class="navbar-nav"
          v-if="menus.length > 0">
        <router-link class="nav-link"
                     :class="{'active': (activeMenuId === menu.id)}"
                     :key="index"
                     :to="menu.url"
                     v-for="(menu, index) in menus">
          <li class="nav-item text-center"
              :class="menu.class"
              :id="menu.id">
            <div class="d-flex align-items-center justify-content-center">
              <img class="nav-icon"
                   :src="menu.icon"
                   :alt="menu.id" />
              <span class="tooltiptext tooltip-right"
                    v-if="menu.tooltip !== ''">
                {{ menu.tooltip }}
              </span>
            </div>
          </li>
        </router-link>

        <router-link class="nav-link"
                     to="/"
                     @click.native="onLogout">
          <li class="nav-item text-center">
            <div class="d-flex align-items-center justify-content-center">
              <span>Logout</span>
            </div>
          </li>
        </router-link>
      </ul>

      <!-- Without Menus -->
      <ul class="navbar-nav" v-else>
        <router-link class="nav-link"
                     to="/"
                     @click.native="onLogout">
          <li class="nav-item text-center">
            <div class="d-flex align-items-center justify-content-center">
              <span>Logout</span>
            </div>
          </li>
        </router-link>
      </ul>
    </nav>

    <!-- Tour -->
    <div class="tour-container">
      <v-tour name="mainTour"
            :steps="steps"
            :callbacks="callbacks"
            :options="tourOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <template v-for="(step, index) of tour.steps">
              <v-step v-if="tour.currentStep === index"
                    :key="index"
                    :step="step"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels">
              <div slot="header" v-if="step.header.title != null">
                <div class="v-step__header" v-if="step.header">
                  <div v-if="step.header.title"
                       v-html="step.header.title">
                  </div>
                </div>
              </div>
              <div slot="actions">
                <button class="btn btn-primary m-1 action-btn"
                        @click="tour.previousStep"
                        v-if="tour.currentStep != 0 &&
                          tour.currentStep != (steps.length - 1)">
                  Previous
                </button>
                <button class="btn btn-primary m-1 action-btn"
                        @click="tour.nextStep"
                        v-if="tour.currentStep != (steps.length - 1)">
                  Next
                </button>
                <button class="btn btn-primary m-1 action-btn"
                        @click="nextGettingStarted"
                        v-if="tour.currentStep == (steps.length - 1)">
                  Next
                </button>
              </div>
            </v-step>
            </template>
          </transition>
        </template>
      </v-tour>
      <div class="arrow-left"></div>
      <div class="tour-overlay"></div>
      <div class="tour-helperLayer"></div>
    </div>
  </div>
</template>

<script>
  import mainIcon from "../../assets/images/new_main_icon.png";
  import categIcon from "../../assets/images/new_categories_icon.png";
  import musicIcon from "../../assets/images/new_my_music_icon.png";
  import videosIcon from "../../assets/images/new_my_videos_icon.png";
  import schoolIcon from "../../assets/images/new_school_icon.png";
  import favoriteIcon from "../../assets/images/favorite_icon.png";
  import { mapGetters, mapActions } from 'vuex';
  import * as $ from 'jquery';
  require('vue-tour/dist/vue-tour.css');

  export default {
    name  : 'UserSidebar',
    props : {
      startTour : Boolean,
    },
    data() {
      return {
        menus     : [],
        steps     : [],
        callbacks : {
          onStart        : this.onStartTour,
          onNextStep     : this.onNextStep,
          onPreviousStep : this.onPreviousStep,
          onStop         : this.onStop,
        },
        tourOptions : {
          useKeyboardNavigation : false,
        },
        activeStep : null,
      }
    },
    created() {
      this.getLevels();
    },
    computed : {
      ...mapGetters({
        levels : 'gamification/levels',
        user   : 'user/user',
      }),
      userLevel() {
        // eslint-disable-next-line no-undef
        const lvl = _.find(this.levels, (o) => {
          return o.level == this.user.userLevel.level;
        });

        return lvl || {};
      },
      expPercentage() {
        return this.userLevel.threshold &&
          this.user.userLevel.exp_points ?
            (this.user.userLevel.exp_points /
            this.userLevel.threshold) * 100 : 0;
      },
      userPhotoUrl() {
        return this.user.profile_img_url;
      },
      activeMenuId() {
        return this.$route.meta.menuId || '';
      },
      activeMenu() {
        if (this.user.activation_used == 0) {
          return [ {
            id      : 'main',
            url     : '/browse/main',
            icon    : mainIcon,
            tooltip : '',
          }, {
            id      : 'categories',
            url     : '/browse/categories',
            icon    : categIcon,
            tooltip : 'Pick a video to watch and learn!',
            class   : 'v-step-3',
          }, {
            id      : 'live-streaming',
            url     : '/browse/live-streaming',
            icon    : videosIcon,
            tooltip : '',
            class   : 'v-step-4',
          }, {
            id      : 'my-videos',
            url     : '/browse/my-videos',
            icon    : favoriteIcon,
            tooltip : '',
            class   : 'v-step-5',
          }, {
            id      : 'music',
            url     : '/browse/music',
            icon    : musicIcon,
            tooltip : '',
            class   : 'v-step-6',
          } ];
        }

        switch(this.user.user_type) {
        case 1 : // Kid
        case 3 : // Educator
        case 4 : // Champion
          return [ {
            id      : 'main',
            url     : '/browse/main',
            icon    : mainIcon,
            tooltip : '',
          }, {
            id      : 'categories',
            url     : '/browse/categories',
            icon    : categIcon,
            tooltip : 'Pick a video to watch and learn!',
            class   : 'v-step-3',
          }, {
            id      : 'live-streaming',
            url     : '/browse/live-streaming',
            icon    : videosIcon,
            tooltip : '',
            class   : 'v-step-4',
          }, {
            id      : 'my-videos',
            url     : '/browse/my-videos',
            icon    : favoriteIcon,
            tooltip : '',
            class   : 'v-step-5',
          }, {
            id      : 'music',
            url     : '/browse/music',
            icon    : musicIcon,
            tooltip : '',
            class   : 'v-step-6',
          }, {
            id      : 'school',
            url     : '/browse/school',
            icon    : schoolIcon,
            tooltip : '',
            class   : 'v-step-7',
          } ]
        case 2 : // Parent
          return [ {
            id      : 'main',
            url     : '/browse/main',
            icon    : mainIcon,
            tooltip : '',
          }, {
            id      : 'categories',
            url     : '/browse/categories',
            icon    : categIcon,
            tooltip : 'Pick a video to watch and learn!',
            class   : 'v-step-3',
          }, {
            id      : 'live-streaming',
            url     : '/browse/live-streaming',
            icon    : videosIcon,
            tooltip : '',
            class   : 'v-step-4',
          }, {
            id      : 'my-videos',
            url     : '/browse/my-videos',
            icon    : favoriteIcon,
            tooltip : '',
            class   : 'v-step-5',
          }, {
            id      : 'music',
            url     : '/browse/music',
            icon    : musicIcon,
            tooltip : '',
            class   : 'v-step-6',
          } ]
        case 6 :
          return [];
        default : // Influencer
          return [ {
            id      : 'main',
            url     : '/browse/main',
            icon    : mainIcon,
            tooltip : '',
          }, {
            id      : 'categories',
            url     : '/browse/categories',
            icon    : categIcon,
            tooltip : 'Pick a video to watch and learn!',
            class   : 'v-step-3',
          }, {
            id      : 'my-videos',
            url     : '/browse/my-videos',
            icon    : favoriteIcon,
            tooltip : 'Upload new videos or re-watch your cool videos!',
            class   : 'v-step-4',
          }, {
            id      : 'music',
            url     : '/browse/music',
            icon    : musicIcon,
            tooltip : '',
            class   : 'v-step-5',
          } ]
        }
      },
      activeSteps() {
        if (this.user.activation_used == 0) {
          return [ {
            target  : '.v-step-0',
            params  : { placement : 'right' },
            header  : { title : 'Getting Started' },
            content : 'This is your badge. Earn more badges as you level up! ' +
              'Win discounts and prizes (Coming soon...)',
          }, {
            target  : '.v-step-1',
            params  : { placement : 'right' },
            header  : { title : 'Profile' },
            content : 'Click this icon to view or edit your profile',
          }, {
            target  : '.v-step-2',
            params  : { placement : 'right' },
            header  : { title : 'User Level' },
            content : 'This is your level. Keep watching H3 Videos to ' +
              'Level Up. Walk through the end of this tutorial to earn your ' +
              'first points!',
          }, {
            target  : '.v-step-3',
            params  : { placement : 'right' },
            header  : { title : 'Media Categories' },
            content : 'Click this tab to see all the latest Healthy Hip Hop ' +
              'content!',
          }, {
            target  : '.v-step-4',
            params  : { placement : 'right' },
            header  : { title : 'Live Streaming' },
            content : 'Click this tab to see H3 live stream!',
          }, {
            target  : '.v-step-5',
            params  : { placement : 'right' },
            header  : { title : 'Your Videos' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop videos!',
          }, {
            target  : '.v-step-6',
            params  : { placement : 'right' },
            header  : { title : 'Your Music' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop music!',
          }, {
            target  : '.v-step-7',
            params  : { placement : 'right' },
            header  : { title : 'Are you ready?' },
            content : 'Start with editing your avatar and earn your very ' +
              'first H3 badge!',
          } ];
        }

        switch(this.user.user_type) {
        case 1 : // Kid
        case 3 : // Educator
        case 4 : // Champion
          return [ {
            target  : '.v-step-0',
            params  : { placement : 'right' },
            header  : { title : 'Getting Started' },
            content : 'This is your badge. Earn more badges as you level up! ' +
              'Win discounts and prizes (Coming soon...)',
          }, {
            target  : '.v-step-1',
            params  : { placement : 'right' },
            header  : { title : 'Profile' },
            content : 'Click this icon to view or edit your profile',
          }, {
            target  : '.v-step-2',
            params  : { placement : 'right' },
            header  : { title : 'User Level' },
            content : 'This is your level. Keep watching H3 Videos to ' +
              'Level Up. Walk through the end of this tutorial to earn your ' +
              'first points!',
          }, {
            target  : '.v-step-3',
            params  : { placement : 'right' },
            header  : { title : 'Media Categories' },
            content : 'Click this tab to see all the latest Healthy Hip Hop ' +
              'content!',
          }, {
            target  : '.v-step-4',
            params  : { placement : 'right' },
            header  : { title : 'Live Streaming' },
            content : 'Click this tab to see H3 live stream!',
          }, {
            target  : '.v-step-5',
            params  : { placement : 'right' },
            header  : { title : 'Your Videos' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop videos!',
          }, {
            target  : '.v-step-6',
            params  : { placement : 'right' },
            header  : { title : 'Your Music' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop music!',
          }, {
            target  : '.v-step-7',
            params  : { placement : 'right' },
            header  : { title : 'School' },
            content : 'Click this tab to explore your school and join ' +
              'classrooms!',
          }, {
            target  : '.v-step-8',
            params  : { placement : 'right' },
            header  : { title : 'Are you ready?' },
            content : 'Start with editing your avatar and earn your very ' +
              'first H3 badge!',
          } ];
        case 2 : // Parent
          return [ {
            target  : '.v-step-0',
            params  : { placement : 'right' },
            header  : { title : 'Getting Started' },
            content : 'This is your badge. Earn more badges as you level up! ' +
              'Win discounts and prizes (Coming soon...)',
          }, {
            target  : '.v-step-1',
            params  : { placement : 'right' },
            header  : { title : 'Profile' },
            content : 'Click this icon to view or edit your profile',
          }, {
            target  : '.v-step-2',
            params  : { placement : 'right' },
            header  : { title : 'User Level' },
            content : 'This is your level. Keep watching H3 Videos to ' +
              'Level Up. Walk through the end of this tutorial to earn your ' +
              'first points!',
          }, {
            target  : '.v-step-3',
            params  : { placement : 'right' },
            header  : { title : 'Media Categories' },
            content : 'Click this tab to see all the latest Healthy Hip Hop ' +
              'content!',
          }, {
            target  : '.v-step-4',
            params  : { placement : 'right' },
            header  : { title : 'Live Streaming' },
            content : 'Click this tab to see H3 live stream!',
          }, {
            target  : '.v-step-5',
            params  : { placement : 'right' },
            header  : { title : 'Your Videos' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop videos!',
          }, {
            target  : '.v-step-6',
            params  : { placement : 'right' },
            header  : { title : 'Your Music' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop music!',
          }, {
            target  : '.v-step-7',
            params  : { placement : 'right' },
            header  : { title : 'Are you ready?' },
            content : 'Start with editing your avatar and earn your very ' +
              'first H3 badge!',
          } ];
        default : // Influencer
          return [ {
            target  : '.v-step-0',
            params  : { placement : 'right' },
            header  : { title : 'Getting Started' },
            content : 'This is your badge. Earn more badges as you level up! ' +
              'Win discounts and prizes (Coming soon...)',
          }, {
            target  : '.v-step-1',
            params  : { placement : 'right' },
            header  : { title : 'Profile' },
            content : 'Click this icon to view or edit your profile',
          }, {
            target  : '.v-step-2',
            params  : { placement : 'right' },
            header  : { title : 'User Level' },
            content : 'This is your level. Keep watching H3 Videos to ' +
              'Level Up. Walk through the end of this tutorial to earn your ' +
              'first points!',
          }, {
            target  : '.v-step-3',
            header  : { title : 'Media Categories' },
            params  : { placement : 'right' },
            content : 'Click this tab to see all the latest Healthy Hip Hop ' +
              'content!',
          }, {
            target  : '.v-step-4',
            header  : { title : 'Your Videos' },
            params  : { placement : 'right' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop videos!',
          }, {
            target  : '.v-step-5',
            params  : { placement : 'right' },
            header  : { title : 'Your Music' },
            content : 'Click this tab to watch all your favorite Healthy Hip ' +
              'Hop music!',
          }, {
            target  : '.v-step-6',
            params  : { placement : 'right' },
            header  : { title : 'Are you ready?' },
            content : 'Start with editing your avatar and earn your very ' +
              'first H3 badge!',
          } ];
        }
      },
    },
    watch : {
      '$route.path'(value) {
        if (value != '/browse/main') {
          this.$tours['mainTour'].stop();
          $('.tour-overlay').hide();
          $('.tour-helperLayer').hide();
        }
      },
      startTour(value) {
        if (value === true)
          this.gettingStarted();
      },
      // eslint-disable-next-line no-unused-vars
      '$store.state.user.user.user_type'(value) {
        this.menus = this.activeMenu;
        this.steps = this.activeSteps;
      },
      // eslint-disable-next-line no-unused-vars
      '$store.state.user.user.activation_used'(value) {
        this.menus = this.activeMenu;
      },
    },
    methods : {
      ...mapActions({
        'getLevels'      : 'gamification/getLevels',
        'getUpdatedUser' : 'user/getUpdatedUser',
        'logout'         : 'auth/logout',
      }),

      /**
       * On Logout
       */
      onLogout() {
        this.logout();
      },

      /**
       * Start Tour
       */
      onStartTour() {
        $('.tour-overlay').show();
        $('.tour-helperLayer').show();
        this.moveHelperLayer($(this.steps[0].target));
        this.activeStep = 1;
      },

      /**
       * Next Tour Step
       * @param step
       */
      onNextStep(step) {
        this.moveHelperLayer($(this.steps[step + 1].target));
        this.activeStep++;
      },

      /**
       * Previous Tour Step
       * @param step
       */
      onPreviousStep(step) {
        this.moveHelperLayer($(this.steps[step - 1].target));
        this.activeStep--;
      },

      /**
       * Stop Tour
       */
      async onStop() {
        $('.tour-overlay').hide();
        $('.tour-helperLayer').hide();
        $('.tour-highlight').removeClass('tour-highlight');
        $('.tour-container').hide();
      },

      /**
       * Handle Helper Layer
       * @param target
       */
      moveHelperLayer(target) {
        $('.tour-highlight').removeClass('tour-highlight');
        target.addClass('tour-highlight');
        if(target[0].tagName == 'LI') {
          target.parent().addClass('tour-highlight');
          $('.tour-helperLayer').addClass('tour-highlight');
        }
        const offset = target.offset();
        const height = target[0].clientHeight;
        const width = target[0].clientWidth;
        $('.tour-helperLayer').css('transform', 'translate(' + offset.left +
          'px, ' + offset.top + 'px)');
        $('.tour-helperLayer').css('width', width + 'px');
        $('.tour-helperLayer').css('height', height + 'px');
      },

      /**
       * Show Getting Started
       */
      gettingStarted() {
        $('.tour-container').hide();
        if (this.$route.path === '/browse/main') {
          $('.tour-container').show();
          this.$tours['mainTour'].start();

          $('.tour-overlay').hide();
          $('.tour-helperLayer').hide();
        }
      },

      /**
       * Go to next Getting Started
       */
      nextGettingStarted() {
        this.$router.push('/profile');
      },
    },
    mounted() {
      $('.tour-container').hide();
      this.menus = this.activeMenu;
      this.steps = this.activeSteps;

      if (this.startTour)
        this.gettingStarted();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/user_sidebar";
</style>
